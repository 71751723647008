<template>
    <v-container>
        <v-row v-if="permission_error">
            <w-whiteboard title="Error 401" text="You do not have permission to login via this method" :go_back_button="false"/>
        </v-row>

        <v-row v-if="loading">
            <w-whiteboard title="Loading" text="Waiting for redirection" :go_back_button="false"/>
        </v-row>
    </v-container>
</template>

<script>
    import Whiteboard from "../../components/w/Whiteboard";
    export default {
        components: {Whiteboard},
        data() {
            return {
                loading: true,
                permission_error: false,
            }
        },
        mounted() {
            this.redirect()
        },
        methods: {
            permission_intersect(groups, intersection){
                for(let g of groups)
                    if(intersection.includes(g.id))
                        return true;

                return false;
            },

            async redirect(){
                let user = this.$store.getters.user;

                if (Object.entries(user).length === 0){

                    let res = await this.$hpost( '/admins/link-login/', {
                        key: this.$route.params.key,
                        user: this.$route.params.user,
                        invoice_id: this.$route.params.invoice_id});

                    if(res.status < 200 || res.status >=300)
                        this.permission_error = true;
                    else
                        await this.$store.dispatch('loginFromLink', res.data);

                }

                await this.$store.hotUpdate('getters');
                user = this.$store.getters.user;

                this.loading = false;

                if(Object.entries(user).length !== 0)
                    if(this.permission_intersect(user.groups, [1, 2, 3, 6, 12, 15, 21]))
                        this.$router.push('/c-panel/factory/edit-pi/' + this.$route.params.invoice_id);

            }
        }
    }
</script>
